export const BREATHING_CONSTANTS = {
  IN_T: 5.5,
  EX_T: 5.5,
  PRACTICE_CYCLE_NUM: 2, // 처음 2 사이클 이후부터 counter start (연습용 2사이클)
  INTERVAL_THRESHOLD: 1000,
  UNCONSCIOUS_INTERVAL_THRESHOLD: 500,
};

export const SESSION_TEXTS = {
  START_INSTRUCTION: '준비가 되셨다면 \n화면을 한번 탭하여 시작합니다!',
  INHALE_INSTRUCTION: '화면을 길게 누르면서\n 숨을 깊게 들이마십니다',
  EXHALE_INSTRUCTION: '손을 떼면서\n 좀 더 천천히 내쉽니다',
  UNCONSCIOUS_MODE_INSTRUCTION:
    '이제, 손을 내려놓고\n음악과 함께 호흡하세요\n\n언제든 화면을 터치할 수 있어요',
};

export const MODAL_TEXTS = {
  HEADER: '테라피 종료',
  CONGRATS: '수고하셨어요',
  INSTRUCTION_1: '마음이 안정되었다면',
  INSTRUCTION_2: '눈을 감은 채 고요함을 느껴보세요',
};
