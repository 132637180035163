import { useRef, useState } from 'react';
import * as DataProcessing from '../../modules/DataProcessing';
import { AJsonTypes } from '../../modules/types/aJson.types';
import { ModeEnum } from '../../enums/mode.enum';
import { BREATHING_CONSTANTS, SESSION_TEXTS } from './_constant';
import { getAudioPlayer } from '../../modules/audio/audioPlayer';

export const useBreathingSession = (
  patientSeq: string,
  session_length: number,
  timer: number
) => {
  const audioPlayer = getAudioPlayer(session_length);
  const [breathCount, setBreathCount] = useState(0);
  const [mainTextInSession, setMainTextInSession] = useState(
    '화면을 길게 누르면서\n 숨을 깊게 들이마십니다'
  );
  const impressiveData = useRef<AJsonTypes[]>([]);
  const [isActivatedInteraction, setIsActivatedInteraction] = useState(false);
  const [unconsciousMode, setUnconsciousMode] = useState(false);
  const [inhaleUnconsciousMode, setInhaleUnconsciousMode] = useState(false);
  const avgInhale = useRef(0);
  const avgExhale = useRef(0);

  const [isRunning, setIsRunning] = useState(false);

  // 무의식모드 시작: 직전 들숨, 날숨 들의 평균값 계산, 항상 들숨부터 시작하게 설정
  const unconsciousModeStart = () => {
    impressiveData.current.splice(-2, 2);
    avgInhale.current = DataProcessing.calculateAvg(impressiveData.current, 1);
    avgExhale.current = DataProcessing.calculateAvg(impressiveData.current, 2);
    setUnconsciousMode(true);
    setInhaleUnconsciousMode(false);
    setMainTextInSession(
      '이제, 손을 내려놓고\n음악과 함께 호흡하세요\n\n언제든 화면을 터치할 수 있어요'
    );
    DataProcessing.updateData(patientSeq, impressiveData.current);
  };

  // 들숨 이벤트
  const inhaleEvt = (mode: ModeEnum) => {
    let res: any = null;
    const interval = DataProcessing.calcInterval(impressiveData.current); // in -> ex
    if (interval >= 1000) {
      setBreathCount((breathCount) => breathCount + 1);
      if (!isRunning && breathCount >= BREATHING_CONSTANTS.PRACTICE_CYCLE_NUM) {
        setIsRunning(true);
      }
    }

    if (mode === ModeEnum.CONSCIOUS) {
      setUnconsciousMode(false);
      setMainTextInSession(SESSION_TEXTS.INHALE_INSTRUCTION);
      res = DataProcessing.procData(impressiveData.current, 1, 1);
    } else {
      if (!unconsciousMode) {
        return;
      }
      res = DataProcessing.procData(impressiveData.current, 1, 0);
    }

    if (res === null) {
      return;
    }

    // 측정데이터 처리
    impressiveData.current.push(res);
    setIsActivatedInteraction(true);

    // 최초 시작시에는 음원 컨트롤 생략
    if (timer === 0) {
      return;
    }

    // handleInhale(timer);
    audioPlayer.stopAllExhaleTracks();
    audioPlayer.playAllInhaleTracks();
  };

  const exhaleEvt = (mode: ModeEnum) => {
    let res: any = null;
    const interval = DataProcessing.calcInterval(impressiveData.current); // in -> ex
    if (interval >= BREATHING_CONSTANTS.INTERVAL_THRESHOLD) {
      if (!isRunning && breathCount >= BREATHING_CONSTANTS.PRACTICE_CYCLE_NUM) {
        setIsRunning(true);
      }
    }

    if (mode === ModeEnum.CONSCIOUS) {
      if (interval < BREATHING_CONSTANTS.UNCONSCIOUS_INTERVAL_THRESHOLD) {
        unconsciousModeStart();
        return;
      }
      setUnconsciousMode(false);
      setMainTextInSession(SESSION_TEXTS.EXHALE_INSTRUCTION);
      res = DataProcessing.procData(impressiveData.current, 2, 1);
    } else {
      if (!unconsciousMode) {
        return;
      }
      res = DataProcessing.procData(impressiveData.current, 2, 0);
    }

    if (res === null) {
      return;
    }

    // 측정데이터 처리
    impressiveData.current.push(res);
    setIsActivatedInteraction(false);

    // 최초 시작시에는 음원 컨트롤 생략
    if (timer === 0) {
      return;
    }

    // handleExhale(timer);

    audioPlayer.stopAllInhaleTracks();
    audioPlayer.playAllExhaleTracks();
  };

  const calculateTimeout = (avg: number, T: number) => {
    return Math.round(
      (60 /
        Math.max(60 / (avg / 1000 + Math.log(1 + 0.05 * breathCount)), T * 2)) *
        1000
    );
  };

  return {
    breathCount,
    setBreathCount,
    mainTextInSession,
    setMainTextInSession,
    impressiveData,
    isActivatedInteraction,
    setIsActivatedInteraction,
    unconsciousMode,
    setUnconsciousMode,
    inhaleUnconsciousMode,
    setInhaleUnconsciousMode,
    avgInhale,
    avgExhale,
    inhaleEvt,
    exhaleEvt,
    unconsciousModeStart,
    calculateTimeout,
    isRunning,
    setIsRunning,
    timer,
  };
};
