import { useRef } from 'react';
import Typography from '@mui/material/Typography';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import { useNavigate } from 'react-router-dom';
import '../assets/css/App.css';
import useInterval from '../modules/useInterval';
import Timer from '../component/Timer';

function SessionInfo(props: any) {
  let navigate = useNavigate();

  const elapsed = useRef(1);

  //  세션시작 타이머
  useInterval(async () => {
    if (elapsed.current > 3) {
      navigate(props.url, { replace: true });
    } else {
      elapsed.current += 1;
    }
  }, 1000);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div>
        <DarkModeRoundedIcon
          sx={{ color: 'white', fontSize: 50, position: 'relative' }}
        />
      </div>
      <Typography variant="h5" style={{ color: 'white', fontWeight: 'bold' }}>
        당신의 호흡에 맞춰
      </Typography>
      <Typography
        variant="h5"
        style={{ color: 'white', fontWeight: 'bold', marginTop: 16 }}
      >
        <span style={{ color: '#89B7C6' }}>음악</span>을 만들고 있어요
      </Typography>
      <Typography variant="body2" style={{ color: 'white', margin: 16 }}>
        점점 깊게 호흡해봅니다
      </Typography>
      <Timer />
    </div>
  );
}

export default SessionInfo;
