import { useEffect, useState } from 'react';
import audioContextManager from '../../modules/audio/audioContext';

export const useAudioContext = () => {
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const initializeAudio = async () => {
      try {
        await audioContextManager.resumeAudioContext();
        setInitialized(true);
      } catch (error) {
        setError(
          error instanceof Error
            ? error
            : new Error('Failed to initialize audio context')
        );
        console.error('Error loading sounds:', error);
      }
    };

    initializeAudio();
  }, []);

  return {
    initialized,
    error,

    // 위에서 audioContext resume 을 하고 여기서 getAudioContext 를 호출하는 이유는 일부 브라우져 정책에 의해 WebAudio API 사용을 위해서 resume 을 하거나 사용자의 인터렉션을 요구하는 경우가 있기 때문입니다.
    audioContext: audioContextManager.getAudioContext(),
  };
};
