import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'https://www.belltherapeutics.net/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

function handleApiError(error: AxiosError) {
  console.error('API call failed. Error:', error);
}

const getRequest = async <T>(url: string, params?: any) => {
  try {
    const response = await axiosInstance.get<T>(url, { params });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleApiError(error);
    }
  }
};

const postRequest = async <T>(url: string, data: any) => {
  try {
    const response = await axiosInstance.post<T>(url, data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleApiError(error);
    }
  }
};

const deleteRequest = async <T>(url: string) => {
  try {
    const response = await axiosInstance.delete<T>(url);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleApiError(error);
    }
  }
};

const putRequest = async <T>(url: string, data: any) => {
  try {
    const response = await axiosInstance.put<T>(url, data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      handleApiError(error);
    }
  }
};

export { getRequest, postRequest, deleteRequest, putRequest };
