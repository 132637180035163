import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

type DefaultAuth = {
  isAuthorized: boolean;
  patient_seq: string;
  patient_name: string;
  session_length: number;
  access_start: string;
  access_end: string;
  exc_day: string;
  op_from: number;
  today_exc_cnt?: number;
  latest_avg?: number;
  pre_latest_avg?: number;
};

const DEFAULT_AUTH_STATUS: DefaultAuth = {
  isAuthorized: false,
  patient_seq: '',
  patient_name: '',
  session_length: 0,
  access_start: '',
  access_end: '',
  exc_day: '',
  op_from: 0,
};

const AuthContext = createContext<{
  auth: DefaultAuth;
  setAuth: (auth: DefaultAuth) => void;
}>({
  auth: DEFAULT_AUTH_STATUS,
  setAuth: () => {},
});

// TODO: 새로고침 되었을 때, 유저 정보가 session storage에 있다면 즉 로그아웃 되지 않았다면, 다시 유저정보를 context 에 저장하기 -> 다시 정상 화면을 보여줄 수 있음
const AuthProvider = ({ children }: PropsWithChildren) => {
  const [auth, setAuth] = useState<DefaultAuth>(DEFAULT_AUTH_STATUS);
  const nagivate = useNavigate();

  const handleAuth = (auth: DefaultAuth) => {
    setAuth(auth);
  };

  useEffect(() => {
    const isAuthorized = sessionStorage.getItem('isAuthorized');
    const patient_seq = sessionStorage.getItem('patient_seq');
    const patient_name = sessionStorage.getItem('patient_name');
    const session_length = sessionStorage.getItem('session_length');
    const access_start = sessionStorage.getItem('access_start');
    const access_end = sessionStorage.getItem('access_end');
    const exc_day = sessionStorage.getItem('exc_day');
    const op_from = sessionStorage.getItem('op_from');
    const today_exc_cnt = sessionStorage.getItem('today_exc_cnt');
    const latest_avg = sessionStorage.getItem('latest_avg');
    const pre_latest_avg = sessionStorage.getItem('pre_latest_avg');

    if (
      isAuthorized &&
      patient_seq &&
      patient_name &&
      session_length &&
      access_start &&
      access_end &&
      exc_day &&
      op_from
    ) {
      setAuth({
        isAuthorized: true,
        patient_seq,
        patient_name,
        session_length: Number(session_length),
        access_start,
        access_end,
        exc_day,
        op_from: Number(op_from),
        today_exc_cnt: today_exc_cnt ? Number(today_exc_cnt) : undefined,
        latest_avg: latest_avg ? Number(latest_avg) : undefined,
        pre_latest_avg: pre_latest_avg ? Number(pre_latest_avg) : undefined,
      });
    }

    if (isAuthorized) {
      nagivate('/');
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth: handleAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
export { AuthContext, DEFAULT_AUTH_STATUS };
