import { useAuth } from './useAuth';
import { DEFAULT_AUTH_STATUS } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';

export const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const logout = () => {
    setAuth(DEFAULT_AUTH_STATUS);
    sessionStorage.clear();
    navigate('/login');
  };

  return { logout };
};
