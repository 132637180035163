import React, { FormEvent, useEffect, useState } from 'react';
import logo from '../../assets/img/bell_logo.png';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import '../../assets/css/App.css';
import { useLoginAPI } from '../../hooks/auth/useLoginAPI';
import { useLoginForm } from './hooks/useLoginForm';
import {
  button,
  buttonContainer,
  caption,
  fontColorWhite,
  imageContainer,
  input,
  pageContainer,
  snackBar,
} from './loginPage.styles';

export default function LoginPage() {
  const [fadeIn, setFadeIn] = useState(false);

  const {
    loginForm: { id, birth, agree },
    handleLoginFormChange,
    error,
    setError,
  } = useLoginForm();

  const { handleLogin } = useLoginAPI({ setError, id, birth });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin();
  };

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <>
      <Box
        sx={{
          ...pageContainer,
          ...(fadeIn ? { opacity: fadeIn ? 1 : 0 } : null),
        }}
      >
        <Box sx={imageContainer}>
          <img src={logo} alt="" />
        </Box>
        <p></p>
        <form onSubmit={onSubmit}>
          <Stack sx={{ width: '100%', textAlign: 'left' }}>
            <TextField
              sx={input}
              name="id"
              label="id"
              value={id}
              onChange={handleLoginFormChange}
              InputProps={{ style: { color: 'white' } }}
            />
            <p></p>
            <TextField
              sx={input}
              name="birth"
              type="password"
              label="비밀번호(8자리)"
              value={birth}
              onChange={handleLoginFormChange}
              InputProps={{ style: { color: 'white' } }}
            />
            <p></p>
            <FormControlLabel
              control={
                <Checkbox
                  name="agree"
                  checked={agree}
                  sx={fontColorWhite}
                  onChange={handleLoginFormChange}
                />
              }
              label={<Typography sx={fontColorWhite}>이용약관동의</Typography>}
            />
            <Typography variant="caption" sx={caption}>
              이름을 포함한 식별정보를 수집하지 않습니다. 모든 데이터는
              익명화되며, 연구목적으로 활용될 수 있습니다. 로그인 시 이용약관 및
              개인정보 처리방침에 동의하신 것으로 간주합니다.
            </Typography>
            <p></p>
          </Stack>
          <Stack sx={buttonContainer} spacing={2}>
            <Button
              type="submit"
              sx={button}
              variant="contained"
              disabled={!agree}
            >
              로그인
            </Button>
          </Stack>
        </form>
      </Box>
      <Snackbar
        open={error.length > 0}
        onClose={() => setError('')}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message={<Alert severity="error">{error}</Alert>}
        sx={snackBar}
      />
    </>
  );
}
