import { useState, ChangeEvent } from 'react';

type DefaultLoginState = {
  id: string;
  birth: string;
  agree: boolean;
};

export const useLoginForm = () => {
  const [loginForm, setLoginForm] = useState<DefaultLoginState>({
    id: '',
    birth: '',
    agree: false,
  });

  const [error, setError] = useState('');

  const handleLoginFormChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;

    if (name === 'agree' && type === 'checkbox') {
      setLoginForm({ ...loginForm, [name]: checked });
      setError('');
      return;
    }

    setLoginForm({ ...loginForm, [name]: value });
  };

  return { loginForm, handleLoginFormChange, error, setError };
};
