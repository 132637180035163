import { postRequest } from './axios';

// 응답코드 1 이외에 다른 응답코드가 무엇이 있는지 서버 문서나 정보가 없어 알수가 없음. 추후 실제 테스트에서 또 다른 응답코드가 발견되면 추가할게요

const GET_USER_DATA_RESULT_CODE = {
  SUCCESS: 1, // 성공
} as const;

type GetUserDataResultCode =
  (typeof GET_USER_DATA_RESULT_CODE)[keyof typeof GET_USER_DATA_RESULT_CODE];

type GetUserDataResponse = {
  resultcode: GetUserDataResultCode;
  today_exc_cnt: number;
  latest_avg: number;
  pre_latest_avg: number;
};

const getUserData = async (patient_seq: string) => {
  // 정황상 get 요청이기는 하나 API가 post method 로 제작이 되어있어 post로 요청을 보내도록 작성
  return postRequest<GetUserDataResponse>('/info', { id: patient_seq });
};

export { getUserData, GET_USER_DATA_RESULT_CODE };
export type { GetUserDataResponse };
