import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/Login';
import Home from './pages/Home';
import Session from './pages/Session/Session';
import SessionInfo from './pages/SessionInfo';
import ModeSelect from './pages/ModeSelect';
import AuthProvider from './context/AuthProvider';
import RequireAuth from './component/auth/RequireAuth';
import QRCodeLogin from './pages/QRCodeLogin';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/qrcode" element={<QRCodeLogin />} />

          <Route element={<RequireAuth />}>
            <Route path="/" element={<Home />} />
            <Route path="/session" element={<Session />} />
            <Route
              path="/sessioninfo"
              element={<SessionInfo url="/session" />}
            />
            <Route path="/modeselect" element={<ModeSelect />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
