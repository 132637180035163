import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth/useAuth';

const RequireAuth = () => {
  const { auth } = useAuth();
  return auth.isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

export default RequireAuth;
