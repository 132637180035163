import { useEffect } from 'react';

const usePreventClose = () => {
  useEffect(() => {
    const preventClose = (e: any) => {
      e.preventDefault();
      const confirmationMessage =
        '새로고침 하시겠습니까? 처음부터 다시 시작해야 합니다.';
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };
    (() => {
      window.addEventListener('beforeunload', preventClose);
      window.addEventListener('popstate', preventClose);
    })();
    return () => {
      window.removeEventListener('beforeunload', preventClose);
      window.removeEventListener('popstate', preventClose);
    };
  }, []);
};

export default usePreventClose;
