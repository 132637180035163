import axios from 'axios';
import moment from 'moment';
import { AJsonTypes } from './types/aJson.types';
import { UpdateDataTypes } from './types/update-data.types';
// 데이터 처리 모듈

// 측정 데이터 생성 함수
// input : data(array), phase(int), response(int)
export function procData(
  data: any[],
  phase: number,
  response: number
): AJsonTypes {
  const aJson: AJsonTypes = {
    timestamp: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
    phase,
    response,
    interval:
      data.length === 0
        ? 0
        : moment().diff(
            moment(data[data.length - 1].timestamp),
            'milliseconds'
          ),
  };
  return aJson;
}

// 호흡간격 측정 함수
// input : data(array)
// output : millisecond(int)
export function calcInterval(data: any[]) {
  if (data.length === 0) {
    return 10000; // 첫번째는 기준이 없으므로 interval을 임의로 큰수로 지정
  } else {
    return moment().diff(
      moment(data[data.length - 1].timestamp),
      'milliseconds'
    );
  }
}

// 최근 3회의 들숨/날숨 평균 계산함수
// 측정데이터가 6개 미만(들숨,날숨 3개씩) 일시, 가장 최근의 값 리턴
// input : data(array), phase(int)
export function calculateAvg(data: any[], phase: number) {
  let avgInhale: number;
  let avgExhale: number;
  let sumInhale = 0;
  let sumExhale = 0;
  let cntInhale = 0;
  let cntExhale = 0;

  if (data.length < 9) {
    cntInhale = 1;
    cntExhale = 1;
    sumInhale = data.length < 2 ? 0 : data[data.length - 2].interval;
    sumExhale = data.length === 0 ? 0 : data[data.length - 1].interval;
  } else {
    for (let i = data.length - 1; i >= data.length - 6; i--) {
      if (data[i].interval * 1 >= 200) {
        if (data[i].phase === 1) {
          cntExhale += 1;
          sumExhale += data[i].interval;
        } else {
          cntInhale += 1;
          sumInhale += data[i].interval;
        }
      }
    }
  }

  avgInhale = sumInhale / cntInhale;
  avgExhale = sumExhale / cntExhale;
  if (phase === 1) {
    return avgInhale;
  } else {
    return avgExhale;
  }
}

// 측정데이터 전송
function updateDataRequest(data: UpdateDataTypes) {
  return axios
    .post('https://www.belltherapeutics.net/api/updateResult', data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
}

function insertDataRequest(id: string) {
  return axios
    .post(`https://www.belltherapeutics.net/api/insertResult?id=${id}`)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
}

function calcFirstAvg(data: any[], phase: number) {
  let res: number;
  let sum = 0;

  if (phase === 1) {
    // interval from in to ex (inhale)
    for (let i = 1; i < 6; i += 2) {
      sum += data[i].interval;
    }
  } else {
    // interval from ex to in (exhale)
    for (let i = 2; i < 7; i += 2) {
      sum += data[i].interval;
    }
  }
  res = sum / 3;
  return res;
}

export async function updateData(patient_seq: string, data: any[]) {
  if (data) {
    let req: UpdateDataTypes = {
      patient_seq,
      sessionLength: sessionStorage.getItem('session_length'),
      firstIn: data.length < 6 ? 0 : calcFirstAvg(data, 1),
      firstEx: data.length < 7 ? 0 : calcFirstAvg(data, 2),
      data,
    };
    await updateDataRequest(req);
  }
}

export async function insertData(patient_seq: string) {
  await insertDataRequest(patient_seq);
}
