import { GET_USER_DATA_RESULT_CODE, getUserData } from '../../api/user-data';
import { useAuth } from '../auth/useAuth';

export const useGetUserDataAPI = () => {
  const { auth, setAuth } = useAuth();

  const handleGetUserData = async () => {
    const userData = await getUserData(auth.patient_seq);

    if (
      userData?.resultcode &&
      userData?.resultcode === GET_USER_DATA_RESULT_CODE.SUCCESS
    ) {
      setAuth({
        ...auth,
        today_exc_cnt: userData.today_exc_cnt,
        latest_avg: userData.latest_avg,
        pre_latest_avg: userData.pre_latest_avg,
      });

      sessionStorage.setItem(
        'today_exc_cnt',
        userData?.today_exc_cnt.toString()
      );
      sessionStorage.setItem('latest_avg', userData?.latest_avg.toString());
      sessionStorage.setItem(
        'pre_latest_avg',
        userData?.pre_latest_avg.toString()
      );
    }
  };

  return { handleGetUserData };
};
