import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Header from '../component/Header';
import ModalBottom from '../component/modal/ModalBottom';
import { useGetUserDataAPI } from '../hooks/user-data/useGetUserDataAPI';

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { handleGetUserData } = useGetUserDataAPI();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    handleGetUserData();
  }, []);

  const start = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <Header isLogout={true} />
        <div
          style={{
            width: '100%',
            height: '75%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: 'white',
              marginBottom: '1%',
            }}
          >
            안녕하세요,
          </Typography>
          <Typography variant="h6" sx={{ color: 'white', marginBottom: '1%' }}>
            음악과 함께 호흡하고
          </Typography>
          <Typography variant="h6" sx={{ color: 'white', marginBottom: '1%' }}>
            깊은 휴식과 수면을 경험하세요
          </Typography>
        </div>

        <Stack
          sx={{
            position: 'fixed',
            bottom: '6%',
            width: '80%',
            height: '80px',
          }}
          spacing={2}
        >
          <Button
            sx={{
              height: '100%',
              fontSize: '6vw',
              fontWeight: 'bold',
              background: 'white',
              color: '#27234C',
              boxShadow: 'none',
              borderRadius: 30,
            }}
            variant="contained"
            onClick={start}
          >
            소닉 테라피 시작하기
          </Button>
        </Stack>
      </div>

      <ModalBottom
        open={isModalOpen}
        close={closeModal}
        header="이렇게 사용하세요"
      >
        <ul css={{ marginLeft: '-21px', fontSize: '1em' }}>
          <li css={{ marginBottom: '4%' }}>
            화면을 지긋이 누르며 숨을 들이마십니다.
          </li>
          <li style={{ marginBottom: '4%' }}>손을 떼면서 숨을 내쉽니다.</li>
          <li style={{ marginBottom: '4%' }}>
            반복하면서 점점 깊게 호흡합니다.
            <div style={{ fontSize: '0.75rem', opacity: 0.75 }}>
              당신에게 맞춰진 음악은 깊고 길게 호흡할 수 있도록 도와줍니다.
            </div>
            <div style={{ fontSize: '0.75rem', opacity: 0.75 }}>
              의식 모드는 1~3분 사용을 권장합니다.
            </div>
          </li>
          <li style={{ marginBottom: '4%' }}>
            호흡의 속도가 안정되었다면 짧게 한번만 터치합니다.
            <div style={{ fontSize: '0.75rem', opacity: 0.75 }}>
              무의식모드에서는 호흡 패턴에 최적화된 사운드가 자동으로
              만들어집니다.
            </div>
            <div style={{ fontSize: '0.75rem', opacity: 0.75 }}>
              가장 편한 자세로 음악과 호흡에만 집중해보세요.
            </div>
          </li>
          <li style={{ marginBottom: '4%' }}>
            무의식 모드에서 호흡이 불편해진 경우, 언제든지 화면을 터치하여
            호흡속도를 조절할 수 있습니다.
          </li>
        </ul>
      </ModalBottom>
    </>
  );
};

export default Home;
