import { getAudioContext } from './audioContext';
import { SoundNameEnum } from '../../enums/sound-name.enum';
import { getAudioPlayer } from './audioPlayer';

const audioBuffers: { [key in SoundNameEnum]?: AudioBuffer } = {};

export const fetchAudioBuffer = async (
  soundName: string
): Promise<AudioBuffer> => {
  const audioContext = getAudioContext();
  try {
    const importedSound = await import(
      `../../assets/sound/${soundName.toLowerCase()}.mp3`
    );
    const response = await fetch(importedSound.default);
    const arrayBuffer = await response.arrayBuffer();
    return await audioContext.decodeAudioData(arrayBuffer);
  } catch (error) {
    console.error(`Error fetching audio for ${soundName}:`, error);
    throw error;
  }
};

export const preloadAudio = async (sessionLength: number): Promise<void> => {
  const audioPlayer = getAudioPlayer(sessionLength);
  const breathTypes = ['INHALE', 'EXHALE'];
  const numbers = [1, 2, 3];

  const loadPromises = breathTypes.flatMap((breathType) =>
    numbers.map(async (num) => {
      const soundName =
        `${breathType}${num}_${sessionLength}MIN` as SoundNameEnum;
      try {
        const buffer = await fetchAudioBuffer(soundName);
        audioBuffers[soundName] = buffer;
        audioPlayer.createTrack(soundName, buffer);
      } catch (error) {
        console.error(`Error loading sound ${soundName}:`, error);
      }
    })
  );

  await Promise.all(loadPromises);
};

export const getAudioBuffer = (
  soundName: SoundNameEnum
): AudioBuffer | undefined => {
  return audioBuffers[soundName];
};
