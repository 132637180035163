import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAuth } from '../../hooks/auth/useAuth';
import { useNavigate } from 'react-router-dom';
import { Fragment, useEffect, useRef, useState } from 'react';
import usePreventClose from '../../hooks/usePreventClose';
import { useBreathingSession } from './useBreatingSession';
import { useSessionTimer } from './useSessionTimer';
import NoSleep from '@uriopass/nosleep.js';
import * as DataProcessing from '../../modules/DataProcessing';
import { ModeEnum } from '../../enums/mode.enum';
import { BREATHING_CONSTANTS, MODAL_TEXTS, SESSION_TEXTS } from './_constant';
import Header from '../../component/Header';
import State from '../../component/State';
import Interaction from '../../component/Interaction';
import ModalBottom from '../../component/modal/ModalBottom';
import { useAudioContext } from '../../hooks/audio/useAudioContext';
import { preloadAudio } from '../../modules/audio/audioLoader';
import { getAudioPlayer } from '../../modules/audio/audioPlayer';
import { resumeAudioContext } from '../../modules/audio/audioContext';
// CSV 파일들을 미리 import 합니다.
import csv5min from '../../assets/csv/5min.csv';
import csv10min from '../../assets/csv/10min.csv';
import csv30min from '../../assets/csv/30min.csv';
import { isMobileBrowser } from '../../utils/isMobileBrowser';

const nosleep: any = new NoSleep();

const StyledTypography = styled(Typography)`
  color: white;
  white-space: pre-line;
`;

const EndAlertText = styled(Typography)`
  display: flex;
  justify-content: center;
`;

const BeforeFirstTouch = styled(Typography)`
  color: white;
  white-space: pre-line;
  width: 100%;
  height: 100%;
  margin-top: 120px;
  text-align: center;
`;

// TODO: isWebview 를 사용하여 웹뷰에서 실행시에 onMouseDown, onMouseUp 이벤트를 사용하지 않도록 수정
// TODO: queryparams로 길이를 받아 (5분 10분 30분) 재생, sessionStorage의존 삭제
export default function Session() {
  const {
    auth: { session_length = 30, patient_seq = '' },
  } = useAuth();

  const navigate = useNavigate();
  const [isStarted, setIsStarted] = useState(false);

  // dynamic algorithm test
  // const timeLimit = 30;

  const intervalRef = useRef<number | null>(null);

  // 모달창 열기/닫기 플래그
  const [sessionEndAlertModal, setSessionEndAlertModal] = useState(false);

  // 브라우저 닫기, 새로고침, 뒤로가기 방지
  usePreventClose();

  const { timer, setTimer, isRunning, setIsRunning } =
    useSessionTimer(session_length);

  const {
    breathCount,
    setBreathCount,
    mainTextInSession,
    setMainTextInSession,
    impressiveData,
    isActivatedInteraction,
    setIsActivatedInteraction,
    unconsciousMode,
    setUnconsciousMode,
    inhaleUnconsciousMode,
    setInhaleUnconsciousMode,
    avgInhale,
    avgExhale,
    inhaleEvt,
    exhaleEvt,
    unconsciousModeStart,
    calculateTimeout,
  } = useBreathingSession(patient_seq, session_length, timer);

  // 모달창 닫기 이벤트
  const closeModal = () => {
    audioPlayerRef.current?.stopAllExhaleTracks();
    audioPlayerRef.current?.stopAllInhaleTracks();
    audioPlayerRef.current?.reset();

    if (patient_seq) {
      DataProcessing.updateData(patient_seq, impressiveData.current);
    }
    setSessionEndAlertModal(false);
    navigate('/', { replace: true });
  };

  // 연습 두번 사이클 끝나면 타이머 시작, sessionLength에 맞춰 종료
  useEffect(() => {
    if (isRunning) {
      intervalRef.current = window.setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    } else {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
    if (timer >= session_length * 60) {
      setTimer(0);
      setSessionEndAlertModal(true);
      nosleep.disable();
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [isRunning, timer, session_length, breathCount]);

  const handleClick = async (e: any) => {
    e.preventDefault();
    setIsStarted(true);
    // 모바일 브라우저 특성 상 클릭 후 최초 한번 재생 해야 소리나옴
    // HowlerSound.sndPlay(`INHALE1_${String(session_length)}MIN`, 0);

    // AudioContext 재개
    await resumeAudioContext();

    // 볼륨 0으로 샘플 오디오 재생
    if (audioPlayerRef.current) {
      audioPlayerRef.current.createSilentTrack();
    }

    setBreathCount(0);
    await nosleep.enable();
    await DataProcessing.insertData(patient_seq);
  };

  // 눌렀을때 이벤트 (inhale)
  const handleStart = async (e: any) => {
    e.preventDefault();
    await inhaleEvt(ModeEnum.CONSCIOUS);

    setMainTextInSession(SESSION_TEXTS.INHALE_INSTRUCTION);
    setIsActivatedInteraction(true);
  };

  // 뗐을때 이벤트 (exhale)
  const handleEnd = async (e: any) => {
    e.preventDefault();
    await exhaleEvt(ModeEnum.CONSCIOUS);

    setMainTextInSession(SESSION_TEXTS.EXHALE_INSTRUCTION);
    setIsActivatedInteraction(false);
  };

  // passive session timer
  useEffect(() => {
    let tick: any = null;
    if (sessionEndAlertModal) {
      return;
    }
    if (unconsciousMode) {
      let timeout: number;
      if (!inhaleUnconsciousMode) {
        inhaleEvt(ModeEnum.UNCONSCIOUS);
        timeout = calculateTimeout(avgInhale.current, BREATHING_CONSTANTS.IN_T);
      } else {
        exhaleEvt(ModeEnum.UNCONSCIOUS);
        timeout = calculateTimeout(avgExhale.current, BREATHING_CONSTANTS.EX_T);
      }
      tick = setTimeout(() => {
        setInhaleUnconsciousMode(!inhaleUnconsciousMode);
      }, timeout);
    }

    return () => {
      if (tick !== null) {
        clearTimeout(tick);
      }
    };
  }, [unconsciousMode, inhaleUnconsciousMode, sessionEndAlertModal]);

  const { initialized: audioInitialized, error: audioError } =
    useAudioContext();
  const audioPlayerRef = useRef<ReturnType<typeof getAudioPlayer> | null>(null);

  useEffect(() => {
    const fetchCsvData = async (url: string) => {
      const response = await fetch(url);
      const text = await response.text();
      return text;
    };

    const initAudio = async () => {
      if (audioInitialized && !audioError) {
        await preloadAudio(session_length);
        audioPlayerRef.current = getAudioPlayer(session_length);
        let csvUrl = '';
        switch (session_length) {
          case 5:
            csvUrl = csv5min;
            break;
          case 10:
            csvUrl = csv10min;
            break;
          case 30:
            csvUrl = csv30min;
            break;
          default:
            console.error('Invalid session length');
            return;
        }

        const csvData = await fetchCsvData(csvUrl);

        if (audioPlayerRef.current) {
          audioPlayerRef.current.loadVolumeData(csvData, session_length);
        } else {
          console.error('AudioPlayer not initialized');
        }
      }
    };

    initAudio();

    return () => {
      return audioPlayerRef.current?.reset();
    };
  }, [audioInitialized, audioError, session_length]);

  return (
    <Fragment>
      <Header
        impressiveData={impressiveData.current}
        session_length={session_length}
      />
      {!isStarted ? (
        <BeforeFirstTouch onClick={handleClick} variant="h6">
          {SESSION_TEXTS.START_INSTRUCTION}
        </BeforeFirstTouch>
      ) : (
        <Box
          onTouchStart={isMobileBrowser() ? handleStart : undefined}
          onTouchEnd={isMobileBrowser() ? handleEnd : undefined}
          onMouseDown={isMobileBrowser() ? undefined : handleStart}
          onMouseUp={isMobileBrowser() ? undefined : handleEnd}
          sx={{ height: '100%' }}
        >
          <div className="upperWrapper">
            {/* 수동/자동 상태 바 */}
            <State
              isToggled={unconsciousMode}
              left="의식 모드"
              right="무의식 모드"
              onToggle={() => setUnconsciousMode(!unconsciousMode)}
            />
          </div>
          <Box>
            <StyledTypography
              variant="h6"
              sx={{ margin: '86px 0 0 0', textAlign: 'center', height: '100%' }}
            >
              {mainTextInSession}
            </StyledTypography>
          </Box>
        </Box>
      )}

      <Interaction toggle={isActivatedInteraction} />
      <ModalBottom
        open={sessionEndAlertModal}
        close={closeModal}
        header={MODAL_TEXTS.HEADER}
        finished={true}
      >
        <EndAlertText variant="h4" style={{ fontWeight: 'bold' }}>
          {MODAL_TEXTS.CONGRATS}
        </EndAlertText>
        <EndAlertText variant="body1" style={{ marginTop: 20 }}>
          {MODAL_TEXTS.INSTRUCTION_1}
        </EndAlertText>
        <EndAlertText variant="body1">{MODAL_TEXTS.INSTRUCTION_2}</EndAlertText>
      </ModalBottom>
    </Fragment>
  );
}
