import React, { PropsWithChildren, MouseEvent } from 'react';
import '../../assets/css/ModalBottom.css';
import ModalContainer from './ModalContainer';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/auth/useAuth';

type ModalBottomProps = PropsWithChildren & {
  open?: boolean;
  close?: () => void;
  header?: string;
  finished?: boolean;
};

// TODO: 추후 디자인이 새로 나오면 아예 새로 만들어야 할듯 -> confirm 버튼 누를 때 handler 도 외부에서 주입 받아야 할 듯.

const ModalBottom = ({
  open = false,
  close,
  header,
  finished,
  children,
}: ModalBottomProps) => {
  const navigate = useNavigate();
  const { auth } = useAuth();

  const startProgram = () => {
    const { patient_seq } = auth;

    if (!patient_seq) {
      return;
    }

    navigate('/modeselect', { replace: true });
  };

  const onCloseButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    close?.();
  };

  const finishSession = () => {
    navigate('/', { replace: true });
  };

  return (
    // 모달이 열릴때 openModal 클래스가 생성된다.
    <ModalContainer>
      <div className={open ? 'openModal modalBottom' : 'modalBottom'}>
        {open ? (
          <section>
            <header>
              {header}
              <button className="close" onClick={onCloseButtonClick}>
                &times;
              </button>
            </header>
            <main
              style={{
                paddingBottom: '0',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {children}
            </main>
            <footer>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  if (finished) {
                    finishSession();
                  } else {
                    startProgram();
                  }
                }}
              >
                {finished ? '확인' : '시작하기'}
              </button>
            </footer>
          </section>
        ) : null}
      </div>
    </ModalContainer>
  );
};

export default ModalBottom;
