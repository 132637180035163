import { getAudioContext } from './audioContext';
import { AudioTrack } from './audioTrack';
import { SoundNameEnum } from '../../enums/sound-name.enum';

class AudioPlayer {
  private static instance: AudioPlayer | null = null;
  private context: AudioContext;
  private tracks: Map<string, AudioTrack> = new Map();
  private firstPlayTime: number | null = null;
  private totalDuration: number;
  private inhaleExhaleInterval: NodeJS.Timer | null = null;

  private constructor(totalDuration: number) {
    this.context = getAudioContext();
    this.totalDuration = totalDuration;
  }

  public static getInstance(totalDuration: number): AudioPlayer {
    if (!AudioPlayer.instance) {
      AudioPlayer.instance = new AudioPlayer(totalDuration * 60);
    }
    return AudioPlayer.instance;
  }

  public setTotalDuration(duration: number) {
    this.totalDuration = duration;
    this.reset();
  }

  public reset() {
    this.tracks.clear();
    this.firstPlayTime = null;
    if (this.inhaleExhaleInterval) {
      clearInterval(this.inhaleExhaleInterval);
      this.inhaleExhaleInterval = null;
    }
  }

  public createTrack(name: string, audioBuffer: AudioBuffer) {
    const track = new AudioTrack(this.context, name, audioBuffer);
    this.tracks.set(name, track);
  }

  public loadVolumeData(csvData: string, sessionLength: number) {
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');

    headers.slice(1).forEach((trackName, index) => {
      const track = this.tracks.get(
        `${trackName.toUpperCase()}_${sessionLength}MIN`
      );
      if (track) {
        track.volumeData = lines
          .slice(1)
          .map((line) => parseFloat(line.split(',')[index + 1]));
      }
    });
  }

  private scheduleVolumeChanges(track: AudioTrack, startTime: number) {
    for (let i = 0; i < this.totalDuration; i++) {
      const volume = track.volumeData[i % track.volumeData.length] || 0;
      console.log({ volume });
      track.setVolumeAtTime(volume, startTime + i);
    }
  }

  public playTrack(trackName: SoundNameEnum) {
    const track = this.tracks.get(trackName);
    if (track) {
      const currentTime = this.context.currentTime;

      if (this.firstPlayTime === null) {
        this.firstPlayTime = currentTime;
        this.tracks.forEach((audioTrack) => {
          this.scheduleVolumeChanges(audioTrack, this.firstPlayTime || 0);
        });
      }

      // 기존 재생 중인 sourceNode 중지
      if (track.sourceNode) {
        track.sourceNode.stop();
      }

      // 새로운 AudioBufferSourceNode 생성 및 설정
      const source = this.context.createBufferSource();
      source.buffer = track.audioBuffer;
      source.connect(track.gainNode);
      source.start(currentTime); // 항상 처음부터 재생

      // 새로운 sourceNode 설정
      track.sourceNode = source;
    } else {
      console.error(`Track not found: ${trackName}`);
    }
  }

  public stopTrack(trackName: SoundNameEnum, fadeOutDuration = 2) {
    const track = this.tracks.get(trackName.toLowerCase());
    if (track && track.sourceNode) {
      const currentTime = this.context.currentTime;
      const gainNode = track.gainNode;

      // 페이드아웃 시작
      gainNode.gain.cancelScheduledValues(currentTime);
      gainNode.gain.setValueAtTime(gainNode.gain.value, currentTime);
      gainNode.gain.linearRampToValueAtTime(0, currentTime + fadeOutDuration);

      // 페이드아웃이 끝난 후 오디오 소스 중지
      track.sourceNode.stop(currentTime + fadeOutDuration); // 정확한 시간에 소스를 중지
      track.sourceNode = null;
    }
  }

  public playAllInhaleTracks() {
    this.tracks.forEach((track, trackName) => {
      if (trackName.includes('INHALE')) {
        this.playTrack(trackName as SoundNameEnum);
      }
    });
  }

  public playAllExhaleTracks() {
    this.tracks.forEach((track, trackName) => {
      if (trackName.includes('EXHALE')) {
        this.playTrack(trackName as SoundNameEnum);
      }
    });
  }

  public stopAllInhaleTracks() {
    this.tracks.forEach((track, trackName) => {
      if (trackName.includes('INHALE')) {
        this.stopTrack(trackName as SoundNameEnum);
      }
    });
  }

  public stopAllExhaleTracks() {
    this.tracks.forEach((track, trackName) => {
      if (trackName.includes('EXHALE')) {
        this.stopTrack(trackName as SoundNameEnum);
      }
    });
  }

  public createSilentTrack() {
    const oscillator = this.context.createOscillator();
    const gainNode = this.context.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(this.context.destination);

    gainNode.gain.value = 0.0001;

    oscillator.start();
    oscillator.stop(this.context.currentTime + 1); // 1초 동안 재생

    return oscillator;
  }
}

export const getAudioPlayer = (sessionLength: number): AudioPlayer => {
  return AudioPlayer.getInstance(sessionLength);
};

export const updateTotalDuration = (duration: number) => {
  const instance = AudioPlayer.getInstance(0); // 이미 생성된 인스턴스를 가져오기 위해 0을 전달
  instance.setTotalDuration(duration);
};
