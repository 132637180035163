export class AudioTrack {
  name: string;
  audioBuffer: AudioBuffer;
  sourceNode: AudioBufferSourceNode | null = null;
  gainNode: GainNode;
  volumeData: number[] = [];

  constructor(context: AudioContext, name: string, audioBuffer: AudioBuffer) {
    this.name = name;
    this.audioBuffer = audioBuffer;
    this.gainNode = context.createGain();
    this.gainNode.connect(context.destination);
  }

  setVolumeAtTime(volume: number, time: number) {
    this.gainNode.gain.setValueAtTime(volume, time);
  }
}
