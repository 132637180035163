import '../assets/css/State.css';

const State = (props: any) => {
  return (
    <div
      className="state"
      style={{ backgroundPosition: !props.isToggled ? '100% 0%' : '0% 0%' }}
    >
      <span
        style={{
          color: props.isToggled
            ? 'rgba(255, 255, 255, 0.2)'
            : 'rgba(255, 255, 255, 1)',
        }}
        onClick={props.onToggle}
      >
        {props.left}
      </span>
      <span
        style={{
          color: props.isToggled
            ? 'rgba(255, 255, 255, 1)'
            : 'rgba(255, 255, 255, 0.2)',
        }}
        onClick={props.onToggle}
      >
        {props.right}
      </span>
    </div>
  );
};

export default State;
