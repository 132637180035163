// hooks/useSessionTimer.ts
import { useState, useRef, useEffect } from 'react';

export const useSessionTimer = (session_length: number) => {
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = window.setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    } else {
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
    if (timer >= session_length * 60) {
      setTimer(0);
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [isRunning, timer, session_length]);

  return { timer, setTimer, isRunning, setIsRunning };
};
