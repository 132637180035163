import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../assets/css/App.css';
import * as auth from '../modules/Auth';
import { useAuth } from '../hooks/auth/useAuth';

function QRCodeLogin() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setAuth } = useAuth();

  useEffect(() => {
    const userId = searchParams.get('id');
    if (!userId) {
      throw new Error('Cannot Find User Id');
    }

    const qrCodeData = auth.QRCodeLogin(userId);
    qrCodeData.then((res) => {
      try {
        switch (res.resultcode) {
          case 1:
            sessionStorage.setItem('isAuthorized', 'true');
            sessionStorage.setItem('patient_seq', res.patient_seq);
            sessionStorage.setItem('patient_name', res.name);
            sessionStorage.setItem('session_length', res.session_length);
            sessionStorage.setItem('access_start', res.access_start);
            sessionStorage.setItem('access_end', res.access_end);
            sessionStorage.setItem('op_from', res.op_from);
            setAuth({
              ...res,
              isAuthorized: true,
              patient_name: res.name,
            });
            navigate('/');
            break;
          default:
            break;
        }
      } catch (e) {}
    });
  }, [navigate, searchParams]);

  return <div style={{ width: '100%', height: '100%' }}></div>;
}

export default QRCodeLogin;
