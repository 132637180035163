import { login, LOGIN_RESULT_CODE } from '../../api/auth';
import { Dispatch, SetStateAction } from 'react';
import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';

type UseLoginPrams = {
  id: string;
  birth: string;
  setError: Dispatch<SetStateAction<string>>;
};

export const useLoginAPI = ({ id, birth, setError }: UseLoginPrams) => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const handleLogin = async () => {
    const loginResponse = await login({ id, birth });

    try {
      switch (loginResponse?.resultcode) {
        case LOGIN_RESULT_CODE.SUCCESS:
          setAuth({
            ...loginResponse,
            isAuthorized: true,
            patient_name: loginResponse.name,
          });

          sessionStorage.setItem('isAuthorized', 'true');
          sessionStorage.setItem('patient_seq', loginResponse.patient_seq);
          sessionStorage.setItem('patient_name', loginResponse.name);
          sessionStorage.setItem(
            'session_length',
            `${loginResponse.session_length}`
          );
          sessionStorage.setItem('access_start', loginResponse.access_start);
          sessionStorage.setItem('access_end', loginResponse.access_end);
          sessionStorage.setItem('exc_day', loginResponse.exc_day);
          sessionStorage.setItem('op_from', `${loginResponse.op_from}`);

          navigate('/');

          break;
        case LOGIN_RESULT_CODE.INVALID_CREDENTIALS:
          setError('ID와 비밀번호를 다시 한번 확인해 주세요.');
          break;
        case LOGIN_RESULT_CODE.NOT_ALLOWED_DATE:
          setError('접속가능 일자가 아닙니다.');
          break;
        default:
          setError('현재 서버에 접속할 수 없습니다. 관리자에게 문의해 주세요.');
          break;
      }
    } catch {
      setError('현재 서버에 접속할 수 없습니다. 관리자에게 문의해 주세요.');
    }
  };

  return { handleLogin };
};
