import { sx } from '../../utils/sx';

export const input = sx({
  '& label': {
    color: 'white',
    '&.Mui-focused': {
      color: 'white',
    },
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
});

export const pageContainer = sx({
  transition: 'opacity 1s ease-in',
  textAlign: 'center',
  WebkitFontSmoothing: 'antialiased',
  height: ' 90vh',
  width: '100%',
  overflowX: 'hidden',
  overflowY: 'hidden',
});

export const imageContainer = sx({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '5vh 0 2.5vh 0',
  '& > img': {
    width: 'clamp(100px, 30vw, 150px)',
  },
});

export const snackBar = sx({
  transform: 'translateY(-12vh)',
  backgroundColor: 'transparent',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',

  '& .MuiSnackbarContent-root': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
});

export const buttonContainer = sx({
  position: 'fixed',
  bottom: '6%',
  width: '80%',
});

export const button = sx({
  background: 'white',
  color: '#27234C',
  boxShadow: 'none',
  borderRadius: 30,
  height: 48,
});

export const caption = sx({ padding: '16px 0 0 30px', color: 'white' });

export const fontColorWhite = sx({
  color: 'white',
});
